import React, { useState } from "react";
import axios from "axios";
import "./Admin.css";
import blankAvatar from "../../images/avatar.jpg";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

import storage from "../../firebase";
import { BASE_URL } from "../../config";

const AddMember = () => {
  
  // State for member data 
  const [id, setId] = useState("");
  const [gender, setGender] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [birth, setBirth] = useState("");
  const [death, setDeath] = useState("");
  const [spouses, setSpouses] = useState([]);
  const [children, setChildren] = useState([]);
  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");
  const [desc, setDesc] = useState("");

  // State for member image
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState("");
  const [loading, setLoading] = useState(false);
  const [imgName, setImgName] = useState("");
  const [imgSize, setImgSize] = useState("");
  ref(storage, "images/");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!firstname || !id || !gender) {
      alert("Fill the form properly!");
      return;
    } else {
      const data = {
        id: id,
        rels: {
          mother: mother,
          father: father,
          spouses: spouses,
          children: children,
        },
        data: {
          gender: gender,
          "first name": firstname,
          "last name": lastname,
          birth: birth,
          death: death,
          avatar: imageUrls,
          desc: desc
        },
      };

      // console.log(datas.rels.children)
      // console.log(data.data);

      axios
        .post(`${BASE_URL}/admin/addMember`, data)
        .then((res) => {
          setFirstName("");
          setId("");
          setGender("");
          setLastName("");
          setImageUrls("");
          setChildren([]);
          setSpouses([]);
          setBirth("");
          setDeath("")
          setFather("");
          setMother("");
          setDesc("")
          alert("Member added successfully!");
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const uploadFile = () => {
    setLoading(true);
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name}`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref)
        .then((url) => {
          // console.log(snapshot.metadata.name, snapshot.metadata.size/1000, );
          setImgName(snapshot.metadata.name);
          setImgSize(Math.floor(snapshot.metadata.size / 1000));
          setImageUrls(url);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return (
    <div className="upload-wrapper">
      {localStorage.getItem("isAdmin") ? (
        <>
          <div className="img-upload">
            <input
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
              }}
            />
            <div className="img-details">
              {loading && "Loading..."}

              {<button onClick={uploadFile}> Upload Image</button>}

              {
                <img
                  src={imageUrls ? imageUrls : blankAvatar}
                  height="200"
                  width="200"
                  alt="Member"
                />
              }
            </div>
            <p>
              {" "}
              {imgName ? (
                <p>
                  {" "}
                  Image name: {imgName} <br /> Image size: {imgSize} KB
                </p>
              ) : (
                ""
              )}
            </p>
          </div>
          <form onSubmit={handleSubmit} className="upload-form">
          <div className="upload-form-single">
              <input
                type="text"
                placeholder="Member ID *"
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setId(e.target.value);
                }}
                value={id}
              />
            </div>
            <div className="upload-form-single">
              <input
                type="text"
                placeholder="First Name * "
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                value={firstname}
              />
            </div>

            <div className="upload-form-single">
              <input
                type="text"
                placeholder="Last Name "
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastname}
              />
            </div>
            <div className="upload-form-single">
              <input
                type="text"
                placeholder="Father ID "
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setFather(e.target.value);
                }}
                value={father}
              />
            </div>
            <div className="upload-form-single">
              <input
                type="text"
                placeholder="Mother ID "
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setMother(e.target.value);
                }}
                value={mother}
              />
            </div>
            <div className="upload-form-single">
              <input
                type="text"
                placeholder="Description "
                className="border-2 border-green-500 m-4 p-2 textarea"
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
                value={desc}
              />
            </div>
            <div className="upload-form-single">
              <input
                type="number"
                placeholder=" Birth Year "
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setBirth(e.target.value);
                }}
                value={birth}
              />
            </div>

            <div className="upload-form-single">
              <input
                type="number"
                placeholder=" Death Year "
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setDeath(e.target.value);
                }}
                value={death}
              />
            </div>

            <div className="upload-form-single">
              <input
                type="text"
                placeholder="Spouse ID "
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setSpouses(e.target.value);
                }}
                value={spouses}
              />
            </div>
            <div className="upload-form-single">
              <input
                type="text"
                placeholder="child ID "
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setChildren(e.target.value);
                }}
                value={children}
              />
            </div>

            <div className="upload-form-single">
              <input
                type="text"
                placeholder="Gender 8 (F for female, M for male)"
                className="border-2 border-green-500 m-4 p-2"
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                value={gender}
              />
            </div>
            
            <div className="upload-btn">
              <button type="submit" className="">
                Add Member
              </button>
            </div>
          </form>
        </>
      ) : (
        "You are not admin"
      )}
    </div>
  );
};

export default AddMember;
