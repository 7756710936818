import React, { useEffect, useState } from "react";
import axios from "axios";
import demo from "../../images/avatar.jpg";
import { IoMdCopy } from "react-icons/io";
import { MdDeleteForever, MdFace } from "react-icons/md";
import "./Admin.css";
import { BASE_URL } from "../../config";

const AdminHome = () => {
  const [data, setData] = useState(""); 

  const fetchAllMember = async () => {
    let response = await axios.get(`${BASE_URL}/admin/getAllMember`);
    response = await response.data;
    setData(response);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("User ID copied");
      // setCopied("Copied to clipboard!");
    } catch (err) {
      alert("Failed to copy!");
    }
  };

  const deleteUser = async (deleteuserid) => {
    if (deleteuserid) {
      let userData = await axios.delete(
        `${BASE_URL}/admin/deleteMember/${deleteuserid}`
      );
      // alert(deleteuserid)
      alert(userData.data.message);
      window.location.reload();
    } else {
      alert("Enter the user id to delete record!");
    }
  };
  useEffect(() => {
    fetchAllMember();
  }, []);

  return (
    <div className=" grid gap-2 sm:grid-cols-2 lg:grid-cols-3 admin-home-bg ">
      {data.length > 0 ? (
        <>
          {data.map((member) => (
            <div
              className=" mt-4 shadow-lg  member-card  border-b-4 border-sky-400 rounded-md cursor-pointer p-0 duration-300 hover:shadow-sm"
              key={member._id}
            >
              {/* <a href={items.href}>
              <div className=" flex justify-end p-2  align-middle">
                <p
                  onClick={() => deleteUser(member._id)}
                  className="p-2 bg-red-500 text-white rounded-sm flex flex-row text-sm align-middle justify-end"
                >
                  <span className="mt-1">
                    <MdDeleteForever />
                  </span>
                  Delete
                </p>
              </div> */}
              <div className="flex items-center mt-2 pt-3 ml-4 mr-2">
                <div className="flex-none w-24 h-24 rounded-full">
                  <img
                    src={member?.data?.avatar || demo}
                    alt={member.id}
                    className="w-full h-full rounded-full"
                  />
                </div>
                <div className="ml-3 text-left">
                  <span className="block text-gray-800">
                    Name:{" "}
                    <b>
                      {member.data["first name"]} {member.data["last name"]}
                    </b>
                  </span>
                  <span className="block text-gray-800 text-sm">
                    Gender: <b>{member.data.gender}</b>
                  </span>
                  <span className="block text-gray-800 text-sm">
                    Birth : <b>{member?.data?.birth}</b><br/>
                    Death Year: <b>{member?.data?.death || ''} </b>
                  </span>
                  <span className="block text-gray-800 text-sm">
                    Description: <>{member.data?.desc || 'No description provided'}</>
                  </span>
                </div>
              </div>
              <div className="pt-3 ml-4 mr-2 mb-3 text-left  user-id">
                <h3 className="text-base  text-black cursor-copy">
                  Database ID:
                  <p>
                    {" "}
                    {member._id}{" "}
                    <span onClick={() => copyToClipboard(member._id)}>
                      <IoMdCopy />{" "}
                    </span>
                  </p>
                </h3>
                <h3 className="text-sm text-gray-900 mb-2 bg-gray-200 p-2 rounded-sm flex flex-row gap-2 ">
                  <span className="mt-1">
                    <MdFace />
                  </span>{" "}
                  Member ID:{member.id}
                </h3>
                <h3 className="text-gray-900 text-sm mt-1">
                  <b>Father ID:</b> {member.rels?.father}
                </h3>
                <h3 className="text-gray-900 text-sm mt-1">
                  <b>Mother ID:</b> {member.rels?.mother}
                </h3>
                <div>
                  <p>
                    <b>Children ID:</b>{" "}
                    {member.rels?.children.map((ch) => {
                      return <span key={ch}>{ch}, </span>;
                    })}
                  </p>
                  <p>
                    <b>Spouse ID:</b>{" "}
                    {member.rels?.spouses.map((sp) => {
                      return <span key={sp}>{sp}, </span>;
                    })}
                  </p>
                </div>
              </div>
              {/* </a> */}
            </div>
          ))}
        </>
      ) : (
        <p>Loading....</p>
      )}
    </div>
  );
};

export default AdminHome;
