import React from "react";
import B1 from "../../../images/Help/B1.png";
import B2 from "../../../images/Help/B2.png";
import B3 from "../../../images/Help/B3.png";
import B4 from "../../../images/Help/B4.png";

const Delete = () => {
  const addMember = [
    {
      id: "B1",
      step: "Method 1 ",
      desc: "Go to Admin dashboard and click on the Delete button of the member to delete, DONE!",
      important: ["1. A success popup will be displayed"],
      img: B1,
    },
    {
      id: "B2",
      step: "Method 2",
      desc: "Copy the Database ID of the member from admin homepage",
      important: ["1. If you do not want to upload picture then skip the step"],
      img: B3,
    },
    {
      id: "B3",
      step: "",
      desc: 'Go to "Edit and Delete" page and paste the copied ID and click on the delete button',
      important: [],
      img: B4,
    },
    {
      id: "B4",
      step: "",
      desc: "A success message will pop up",
      important: [],
      img: B2,
    },
  ];

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-6 row-gap-10 lg:grid-cols-1">
        <div className="lg:py-6 lg:pr-16">
          <h1 className="text-2xl text-left font-bold mb-6">
            How to delete a member?
          </h1>
          {addMember.map((d) => {
            return (
              <div className="flex" key={d.id}>
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border-2 border-sky-600 rounded-full">
                      <svg
                        className="w-4 text-sky-600"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          strokeMiterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        />
                        <polyline
                          fill="none"
                          strokeMiterlimit="10"
                          points="19,15 12,22 5,15"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-300" />
                </div>

                <div className="pt-1 pb-8 text-left">
                  <p className="mb-2 text-lg text-gray-600 font-bold">
                    {d?.step}
                  </p>
                  <h3 className="text-black text-xl font-semibold">
                    {d?.desc}
                  </h3>
                  <h5 className="ml-6 mt-4 text-blue-700">
                    {d?.important?.map((imp) => {
                      return (
                        <div key={imp}>
                          <p>{imp}</p>
                        </div>
                      );
                    })}
                  </h5>
                  <div
                    style={{ height: "350px", width: "300px" }}
                    className="mt-4 ml-6 "
                  >
                    <img src={d.img} alt={d.step} className="shadow-lg" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Delete;
