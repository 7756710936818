import React, { useState } from "react";
import "./Admin.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import EditMember from "./EditMember";
import AddMember from "./AddMember";
import { IoMdPersonAdd, IoMdHome, IoIosPeople, IoMdLogOut } from 'react-icons/io'
import { FaUserEdit } from 'react-icons/fa'
import AdminHome from "./AdminHome";
import Help from "./Help/Help";
import { BASE_URL } from "../../config";

const Admin = () => {
  const [current, setCurrent] = useState("adminHome");

  const navigate = useNavigate();
  //Logout FUnction
  const logout = () => {
    localStorage.clear();
    navigate("/login");
    axios
      .post(`${BASE_URL}/admin/logout`)
      .then((res) => {
        console.warn(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section>
      <nav  className="admin-nav">
        <Link to="/" style={{ color: '#fff', display: 'flex', gap: '5px', alignItems:'center' , textDecoration:'none' }}>
          <IoIosPeople />Home Page
        </Link>
        <p  onClick={() => setCurrent("adminHome")}>
          <IoMdHome/>Admin Home
        </p>
        <p  onClick={() => setCurrent("add")}>
          <IoMdPersonAdd/> Add 
        </p>
        <p  onClick={() => setCurrent("edit")}>
          <FaUserEdit/>Edit & Delete
        </p>
        <p  onClick={() => setCurrent("help")}>
          <FaUserEdit/>Help
        </p>
        <button onClick={logout} className="">
         <IoMdLogOut/> Log out
        </button>
      </nav>

      <section>
        {current === "adminHome" && <AdminHome />}
        {current === "add" && <AddMember />}
        {current === "edit" && <EditMember />}
        {current === "help" && <Help/>}
      </section>
    </section>
  );
};

export default Admin;
