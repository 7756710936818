import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../config";
import Tree from "./Tree";
import Navbar from "./Navbar/Navbar";
import { BiHelpCircle, BiXCircle } from "react-icons/bi";
import "./../App.css";
import Popup from "./Popup";

const FamilyTree = ({ url }) => {
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    (async () => {
      let response = await axios.get(`${BASE_URL}/admin/getAllMember`);
      response = await response.data;
      setData(response);
    })();
  }, []);

  return (
    <div>
      <Navbar />

      <div className=" bgTree">
        {data.length ? <Tree data={data} /> : "Loading..."}
      </div>
      {toggle && (
        <div className="popup">
          <div onClick={() => setToggle(false)} className="popupClose">
            <BiXCircle />
          </div>
          <div className="popupData">
            <ul>
              <li>Wait for the page to render all the data. It may take some time</li>
              <li>In case the page renders nothing, refresh the page</li>
              <li>You can zoom in and out as per your need</li>
              <li>Click on the cards to see more details</li>
              <li>In case the entire tree is not rendered click on the blue button of the topmost card.</li>
              <li>Watch the video tutorial to understand <a href="https://www.youtube.com/watch?v=ugUNOYJrJ1c" className="text-blue-600 font-semibold" target="_blank">[Click Here]</a></li>
            </ul>
          </div>


          
        </div>

        
      )}
      <span className="helpButton" onClick={() => setToggle(true)}>
        <BiHelpCircle />
      </span>
    </div>
  );
};

export default FamilyTree;
