import React, { useEffect, useState } from "react";
import f3 from "family-chart"; // npm i family-chart
import "./family-chart.css"; // create file 'family-chart.css' in same directory, copy/paste css from examples/create-tree
import axios from "axios";
import * as d3 from "d3";
import "./../App.css";
import { BiXCircle } from "react-icons/bi";
const Tree = ({ data }) => {
  const [card, setCard] = useState(false);
  const [desc, setDesc] = useState("");

  const cont = React.createRef();
  useEffect(() => {
    const store = f3.createStore({
        data: data,
        node_separation: 500,
        level_separation: 250,
      }),
      view = f3.d3AnimationView({
        store,
        cont: document.querySelector("#FamilyChart"),
      }),
      Card = f3.elements.Card({
        store,
        svg: view.svg,
        card_dim: {
          w: 400,
          h: 110,
          text_x: 100,
          text_y: 45,
          img_w: 70,
          img_h: 70,
          img_x: 15,
          img_y: 15,
        },
        card_display: [
          (d) => `${d.data["first name"] || ""} ${d.data["last name"] || ""}`,
          (d) => `${d.data["birth"] || ""} - ${d.data["death"] || ""}`,
        ],
        mini_tree: true,
        link_break: false,
      });

    // view.setCard(Card);
    // store.setOnUpdate((props) => view.update(props || {}));
    // store.update.tree({ initial: true });

    view.setCard(Card);
    store.setOnUpdate((props) => {
      view.update(props || {});

      d3.select(document.querySelector("#FamilyChart"))
        .selectAll(".card")
        .on("click", function () {
          const card_id = this.getAttribute("data-id");
          const cardData = data.find((d) => d.id === card_id);
          // alert(cardData?.data['desc'])

          setCard(true);
          // console.log(cardData?.data['desc'])
          setDesc(cardData?.data["desc"]);
          // setDesc('Data is here')
        });
    });

    store.update.tree({ initial: true });
  }, []);

  return (
    <>
      <div className="f3" id="FamilyChart" ref={cont}></div>
      {card && (
        <div className="memberCardWrapper">
          <div className="memberCard">
            <button onClick={() => setCard(false)}>
              <BiXCircle />
            </button>
            {desc ? desc : "No description found!"}
          </div>
        </div>
      )}
    </>
  );
};

export default Tree;
