import React from 'react'
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const isAdmin = localStorage.getItem('isAdmin');
    if (!isAdmin) {
      // When user is not authenticated
      return <Navigate to="/login" />;
    }
    return children
}

export default ProtectedRoute