import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { AiOutlineMenu } from "react-icons/ai";
// import logo from "../../assets/images/logo.png";
// import logo2 from "../../assets/images/logo2.png";
import styles from "./Navbar.module.css";
// import Image from "next/image";
// import Link from "next/link";
// import desire from '../../assets/images/cars/desire.png'
import { Link } from "react-router-dom"

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className={styles.navbar}>
      {/* Desktop Navbar */}
      <div className={styles.navbar_links}>
        <div className={styles.navbar_links_logo}>
          {/* <img src={''} alt="Tagores" height={40} width={70} /> */}
          <p><Link to="/">Tagore Family Genealogy</Link></p>
        </div>
        <div className={styles.navbar_links_container}>
          <p>
            <Link to="/history">History</Link>
          </p>
          <p>
            <Link to="/researches">Researches</Link>
          </p>
        </div>
      </div>

      <div className={styles.navbar_sign}>
        <button type="button">
          <a href="/contact">Contact</a>
        </button>
      </div>

      {/* Mobile Navbar */}
      <div className={styles.navbar_menu}>
        {toggleMenu ? (
          <RiCloseLine
            color="#000"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <AiOutlineMenu
            color="#000"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div
            className={`${styles.navbar_menu_container}  ${styles.scale_up_center} ${styles.border_gradient} `}
          >
            <div className={styles.navbar_menu_container_links}>
            <p>
            <Link to="/history">History</Link>
          </p>
          <p>
            <Link to="/researches">Researches</Link>
          </p>
            </div>
            <div className={styles.navbar_menu_container_links_sign}>
              <button type="button">
                <Link to="/contact">Contact</Link>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;