import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './Admin.css'

const Login = ({ url }) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username || !password) {
      alert('Fill the form properly!')
    } else {
      const data = {
        username,
        password,
      };

      axios
        .post(url, data)
        .then((res) => {
          if (Object.values(res.data)[0]) {
            localStorage.setItem("isAdmin", true);
          } else {
            localStorage.clear();
          }
          setUserName("");
          setPassword("");
          navigate("/admin");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="login-page">
     <p> Admin Login </p>
      <form onSubmit={handleSubmit}>
        <div className="">
          <input
            type="text"
            placeholder="Username "
            className="border-2 border-green-500 m-4 p-2"
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            value={username}
          />
        </div>
        <div className="">
          <input
            type="password"
            placeholder="Password"
            className="border-2 border-green-500 m-4 p-2"
            onChange={(e) => {
              setPassword(e.target.value);  
            }}
            value={password}
          />
        </div>
        <button type="submit" >
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
