import React from 'react'
import '../App.css'
const ComingSoon = () => {
  return (
    <div className='comingSoon'>
    <h1>Coming Soon</h1>
</div>

  )
}

export default ComingSoon