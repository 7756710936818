import React from "react";
import A1 from "../../../images/Help/A1.png";
import A2 from "../../../images/Help/a2.png";
import A3 from "../../../images/Help/a3.png";
import A4 from "../../../images/Help/a4.png";
import A5 from "../../../images/Help/a5.png";
import A6 from "../../../images/Help/a6.png";
const Add = () => {
  const addMember = [
    {
      id: "A1",
      step: 1,
      desc: "Select a profile picture by clicking on the Choose file button",
      important: [],
      img: A1,
    },
    {
      id: "A2",
      step: 2,
      desc: "Click on the UPLOAD IMAGE button, and wait until the profile picture is displayed",
      important: ["1. If you do not want to upload picture then skip the step"],
      img: A2,
    },
    {
      id: "A3",
      step: 3,
      desc: "Fill the form properly, Fill the member ID field properly, it should be UNIQUE ",
      important: [
        "1. Member ID, First Name, Gender fields must be filled",
        "2. In case of child member do not forget to add the Father and Mother ID [refer the admin homepage for reference]",
        '3. In the gender field Write "F incase of female" and "M in case of M"',
        "4. While adding spouse do not forget to add the proper Spouse ID",
        "5. If the member is alive do not fill the Death Year field",
      ],
      img: A3,
    },
    {
      id: "A4",
      step: 4,
      desc: "If the member has multiple children or spouse add only one, add the other children/spouse in the Update page.",
      important: [
        "1. Do not add multiple spouse/child ID at once, It will result in error ⭐⭐⭐",
      ],
      img: A4,
    },

    {
      id: "A5",
      step: 5,
      desc: "After properly filling the form click on the ADD MEMBER button",
      important: [],
      img: A5,
    },
    {
      id: "A6",
      step: 6,
      desc: "An alert box will pop up with success message, check whether the member was added or not in the Admin Home page YOU ARE DONE!",
      important: [],
      img: A6,
    },
    {
      id: "A7",
      step: 7,
      desc: "If you have added a child or Spouse then make sure to update the data of their parent/spouse after adding the data, otherwise the connection line will not shown in the diagram ⭐⭐⭐",
      important: ['1. If a child has been added then make sure to update the data of his/her parent',
      '2. If a spouse has been added then make sure to update the data of his/her spouse',
    ],
      img: '',
    },
  ];

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-6 row-gap-10 lg:grid-cols-1">
        <div className="lg:py-6 lg:pr-16">
          <h1 className="text-2xl text-left font-bold mb-6">
             How to add a member?
          </h1>
          {addMember.map((d) => {
            return (
              <div className="flex" key={d.id}>
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border-2 border-sky-600 rounded-full">
                      <svg
                        className="w-4 text-sky-600"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          strokeMiterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        />
                        <polyline
                          fill="none"
                          strokeMiterlimit="10"
                          points="19,15 12,22 5,15"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-300" />
                </div>

                <div className="pt-1 pb-8 text-left">
                  <p className="mb-2 text-lg text-gray-600 font-bold">
                    Step {d.step}
                  </p>
                  <h3 className="text-black text-xl font-semibold">
                    {d?.desc}
                  </h3>
                  <h5 className="ml-6 mt-4 text-blue-700">
                    {d?.important?.map((imp) => {
                      return (
                        <div key={imp}>
                          <p>{imp}</p>
                        </div>
                      );
                    })}
                  </h5>
                  <div
                    style={{ height: "250px", width: "300px" }}
                    className="mt-4 ml-6 "
                  >
                    <img src={d.img} alt={d.step} className="shadow-lg" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Add;
