import "./App.css";
import Login from "./components/admin/Login";
import FamilyTree from "./components/FamilyTree";
import { BASE_URL } from "./config/index";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import FileUpload from "./components/admin/FileUpload";
import Admin from "./components/admin/Admin";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ComingSoon from "./components/ComingSoon";
import Contact from "./components/Contact";

function App() {
  const allMemberUrl = `${BASE_URL}/admin/getAllMember`;
  const loginMemberUrl = `${BASE_URL}/admin/login`;
  const logoutUrl = `${BASE_URL}/admin/logout`;

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<FamilyTree />} />
          <Route
            path="/login"
            element={<Login url={loginMemberUrl} />}
          />
          <Route
            path="/history"
            element={<ComingSoon />}
          />
          <Route
            path="/researches"
            element={<ComingSoon />}
          />
          <Route
            path="/contact"
            element={<Contact />}
          />
          
          
          <Route
            path="/login"
            element={<Login url={loginMemberUrl} />}
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
