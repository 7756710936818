import React from 'react'
import Add from './Add'
import Delete from './Delete'
import Edit from './Edit'


const Help = () => {
return(
  <>
  <Add/>
<Delete/>
<Edit/>
  </>
)
  
}


export default Help