import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { REACT_APP_API_KEY, REACT_APP_BUCKET_URL, REACT_APP_AUTHDOMAIN, REACT_APP_APPID,
REACT_APP_MEASUREMENT_ID, REACT_APP_MESSAGING_SENDER_ID,
REACT_APP_PROJECTID, REACT_APP_STORAGE_BUCKET, } from './config/index';

const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTHDOMAIN,
  projectId: REACT_APP_PROJECTID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APPID,
  measurementId: REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app, REACT_APP_BUCKET_URL);

export default storage