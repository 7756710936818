import React from "react";
import B3 from "../../../images/Help/B3.png";
import C1 from "../../../images/Help/C1.png";
import C2 from "../../../images/Help/C2.png";
import C3 from "../../../images/Help/C3.png";

const Edit = () => {
  const addMember = [
    {
      id: "C1",
      step: 1,
      desc: "Go to Admin dashboard and copy the DataBase ID of the user",
      important: [],
      img: B3,
    },
    {
      id: "C2",
      step: 2,
      desc: "Go to Edit page and paste the copied id in the input field and click on the FETCH USER DETAILS button",
      important: ["1. All the user data should be filled automatically"],
      img: C1,
    },
    {
      id: "C3",
      step: 3,
      desc: "Change the fields as per your choice and click on the EDIT MEMBER button",
      important: [
        "1. If you want to add multiple child/spouse then make sure to add ONE ID at a time & repeat the step until all the children/spouses are added",
        "2. Multiple child ID/Spouse ID can not be added at once ⭐⭐⭐",
      ],
      img: C2,
    },
    {
      id: "C4",
      step: 4,
      desc: "If you want to update user image then first select the new image then click on the UPDATE IMAGE button then click on the EDIT member icon",
      important: [
        "1. Make sure to first click on the update image button then on the Update member button, otherwise image will not be updated",
      ],
      img: C3,
    },
  ];

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-6 row-gap-10 lg:grid-cols-1">
        <div className="lg:py-6 lg:pr-16">
          <h1 className="text-2xl text-left font-bold mb-6">
            {" "}
            How to Edit a member?
          </h1>
          {addMember.map((d) => {
            return (
              <div className="flex" key={d.id}>
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border-2 border-sky-600 rounded-full">
                      <svg
                        className="w-4 text-sky-600"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          strokeMiterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        />
                        <polyline
                          fill="none"
                          strokeMiterlimit="10"
                          points="19,15 12,22 5,15"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-300" />
                </div>

                <div className="pt-1 pb-8 text-left">
                  <p className="mb-2 text-lg text-gray-600 font-bold">
                    STEP {d?.step}
                  </p>
                  <h3 className="text-black text-xl font-semibold">
                    {d?.desc}
                  </h3>
                  <h5 className="ml-6 mt-4 text-blue-700">
                    {d?.important?.map((imp) => {
                      return (
                        <div key={imp}>
                          <p>{imp}</p>
                        </div>
                      );
                    })}
                  </h5>
                  <div
                    style={{ height: "400px", width: "400px" }}
                    className="mt-4 ml-6 "
                  >
                    <img src={d.img} alt={d.step} className="shadow-lg" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Edit;
