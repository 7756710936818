import React from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <div className='contact'>
    <h2>For any new addition or alteration, please contact
    <b><u><a  href="mailto: tagorefamily03@gmail.com"> tagorefamily03@gmail.com </a></u></b>
Please don't forget to mention your details with your contact number(a must) in the email body.</h2>

    <p className='mt-8'><Link to="/">Go back</Link></p>
</div>
  )
}

export default Contact