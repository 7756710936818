import { useState, useEffect } from "react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";

import storage from "../../firebase";

function FileUpload() {
  const [imageUpload, setImageUpload] = useState(null);

  const [imageUrls, setImageUrls] = useState("");
  const [loading, setLoading] = useState(false);

  const imagesListRef = ref(storage, "images/");

  const uploadFile = () => {
    setLoading(true);
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name}`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        console.log(snapshot.metadata.name, snapshot.metadata.size / 1000);
        setImageUrls(url);
        setLoading(false);
      });
    });
  };

  return (
    <div className="App">
      <input
        type="file"
        onChange={(event) => {
          setImageUpload(event.target.files[0]);
        }}
      />
      {loading ? (
        "Loading..."
      ) : (
        <button className="bg-green-400" onClick={uploadFile}>
          {" "}
          Upload Image
        </button>
      )}

      {imageUrls ? imageUrls : "No"}
      <img src={imageUrls ? imageUrls : ""} alt="Profile Pic" />
    </div>
  );
}

export default FileUpload;
