import React, { useState } from "react";
import axios from "axios";
import "./Admin.css";
import { isEmpty } from "@firebase/util";
import storage from "../../firebase";
import blankAvatar from "../../images/avatar.jpg";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { BASE_URL } from "../../config";

const EditMember = () => {
  const [id, setId] = useState("");
  const [gender, setGender] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [birth, setBirth] = useState("");
  const [death, setDeath] = useState("");
  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");
  const [imageUrls, setImageUrls] = useState("");
  const [userid, setUserId] = useState("");
  const [desc, setDesc] = useState("");

  //For previous and new spouse 
  const [spouses, setSpouses] = useState([]);
  const [newSpouse, setNewSpouse] = useState("");

  //For previous and new children
  const [children, setChildren] = useState([]);
  const [newChild, setNewChild] = useState("");


  const [data, setData] = useState("");

  // For deleting User
  const [deleteuserid, setDeleteUserId] = useState("");
  const [deletemsg, setDeletemsg] = useState("");
  
  const [imageUpload, setImageUpload] = useState(null);
  // const [imageUrls, setImageUrls] = useState("");
  const [loading, setLoading] = useState(false);
  const [imgName, setImgName] = useState("");
  const [imgSize, setImgSize] = useState("");
  const imagesListRef = ref(storage, "images/");

//* Delete all child 
  // function deleteAllChild(memberID){
  //   function isSpouseEmpty() {
  //     if (newSpouse === "") {
  //       return [...spouses];
  //     } else return [...spouses, newSpouse];
  //   }

  //   const newData = {
  //     id: id,
  //     rels: {
  //       mother: mother,
  //       father: father,
  //       spouses: isSpouseEmpty(),
  //       // children: [...children, newChild],
  //       children: [],
  //     },
  //     data: {
  //       gender: gender,
  //       "first name": firstname,
  //       "last name": lastname,
  //       birth: birth,
  //       death: death,
  //       avatar: imageUrls,
  //     },
  //   };

  //   console.log(newData.rels);

  //   axios
  //     .put(`${BASE_URL}/admin/updateMember/${memberID}`, newData)
  //     .then((res) => {
  //       //   console.log(res);
  //       setUserId("")
  //       setFirstName("");
  //       setId("");
  //       setGender("");
  //       setLastName("");
  //       setImageUrls("");
  //       setChildren("");
  //       setSpouses("");
  //       setBirth("");
  //       setDeath("")
  //       setFather("");
  //       setMother("");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });


  // }




  const deleteUser = async () => {
    if (deleteuserid) {
      let userData = await axios.delete(
        `${BASE_URL}/admin/deleteMember/${deleteuserid}`
      );
      // console.log(userData);
      setDeletemsg(userData.data.message);
      setDeleteUserId("");
      setTimeout(() => {
        setDeletemsg("");
      }, 3000);
    } else {
      alert("Enter the user id to delete record!");
    }
  };

  const fetchUser = async () => {
    if (userid) {
      let response = await axios.get(
        `${BASE_URL}/admin/getMember/${userid}`
      );
      response = await response.data;
      setData(response);

      setFirstName(response.data["first name"]);
      setLastName(response.data["last name"]);
      setGender(response.data.gender);
      setBirth(response.data.birth);
      setDeath(response.data.death);
      setDesc(response.data?.desc)
      setImageUrls(response.data.avatar);
      setId(response.id);
      setSpouses([...response.rels.spouses]);
      setChildren([...response.rels.children]);
      setMother(response.rels.mother);
      setFather(response.rels.father);
      

      console.log(data)
    } else {
      alert("Give the user ID");
    }
  };

  const uploadFile = () => {
    setLoading(true);
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name}`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref)
        .then((url) => {
          setImgName(snapshot.metadata.name);
          setImgSize(Math.floor(snapshot.metadata.size / 1000));
          setImageUrls(url);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    function isChildEmpty() {
      if (newChild === "") {
        return [...children];
      } else {
        const allChild = newChild.split(',')
        return [...allChild]
      }
      // return [...children, newChild];
    }

    function isSpouseEmpty() {
      if (newSpouse === "") {
        return [...spouses];
      } else{
        const allSpouse = newSpouse.split(',')
        return [...allSpouse];
      } 
    }

    
    


    const data = {
      id: id,
      rels: {
        mother: mother,
        father: father,
        spouses: isSpouseEmpty(),
        // children: [...children, newChild],
        children: isChildEmpty(),
      },
      data: {
        gender: gender,
        "first name": firstname,
        "last name": lastname,
        birth: birth,
        death: death,
        desc: desc,
        avatar: imageUrls,
      },
    };

    // console.log(data.rels);

    axios
      .put(`${BASE_URL}/admin/updateMember/${userid}`, data)
      .then((res) => {
        //   console.log(res);
        setUserId("")
        setFirstName("");
        setId("");
        setGender("");
        setLastName("");
        setImageUrls("");
        setChildren("");
        setSpouses("");
        setBirth("");
        setDeath("")
        setFather("");
        setMother("");
        setDesc("")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
    <p className="text-2xl text-center mt-2 mb-2 font-semibold">Edit Member Page</p>
    <section className="edit-page ">
    
      <div className="edit-wrapper">
        <div className="fetch-user p-2">
          <div className="upload-form-single">
            <p>Paste the user ID of the member & press the button: </p>
            <input
              type="text"
              placeholder="User Id "
              className="border-2 border-sky-500 m-4 p-2"
              onChange={(e) => {
                setUserId(e.target.value);
              }}
              value={userid}
            />
          </div>
          <div className="upload-btn">
            <button className="bg-green-400" onClick={fetchUser}>
              {" "}
              Fetch User Details
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="upload-form-single">
            <input
              type="text"
              placeholder="First Name "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstname}
            />
          </div>

          <div className="upload-form-single">
            <input
              type="text"
              placeholder="Last Name "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastname}
            />
          </div>
          <div className="upload-form-single">
            <input
              type="text"
              placeholder="Description "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              value={desc}
            />
          </div>
          <div className="upload-form-single">
            <input
              type="text"
              placeholder="Father ID "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setFather(e.target.value);
              }}
              value={father}
            />
          </div>
          <div className="upload-form-single">
            <input
              type="text"
              placeholder="MOther ID "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setMother(e.target.value);
              }}
              value={mother}
            />
          </div>
          <div className="upload-form-single">
            <input
              type="number"
              placeholder=" Birth Year "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setBirth(e.target.value);
              }}
              value={birth}
            />
          </div>
          <div className="upload-form-single">
            <input
              type="number"
              placeholder=" Death Year "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setDeath(e.target.value);
              }}
              value={death}
            />
          </div>

          <div className="upload-form-single">
          <p>{ spouses.length>0 ? <span>All spouses: { spouses.toString() },</span> : "" }</p>
            <input
              type="text"
              placeholder="Spouse ID "
              className="border-2 border-green-500 m-4 p-2"
              // onChange={(e) => {
              //   setSpouses(e.target.value);
              // }}
              onChange={(e) => {
                setNewSpouse(e.target.value);
              }}
              // value={spouses}
            />
          </div>
          <div className="upload-form-single">
            <p>{ children.length>0 ? <span>All childs: { children.toString() },</span> : "" }</p>
            <input
              type="text"
              placeholder="child ID "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setNewChild(e.target.value);
              }}
              // onChange={()=> addChild}
              // value={children}
            />
          </div>

          <div className="upload-form-single">
            <input
              type="text"
              placeholder="Gender"
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setGender(e.target.value);
              }}
              value={gender}
            />
          </div>
          <div className="upload-form-single">
            <input
              type="text"
              placeholder="Member ID"
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setId(e.target.value);
              }}
              value={id}
            />
          </div>

          <div className="upload-form-single">
            <input
              type="text"
              placeholder="Avatar "
              className="border-2 border-green-500 m-4 p-2"
              onChange={(e) => {
                setImageUrls(e.target.value);
              }}
              value={imageUrls}
            />
          </div>



          <div className="edit-btn">
            <button type="submit">Edit Member</button>
          </div>
        </form>

        {/* <div className="delete-btn">
          <button className="" onClick={(e)=> deleteAllChild(e.target.id)}>
            {" "}
            Delete All child{" "}
          </button>
        </div> */}


      </div>
<div className=" delete-img">
<div className="delete-wrapper">
        <div className="upload-form-single">
          <p>Give the user ID of the member: </p>
          <input
            type="text"
            placeholder="User Id "
            onChange={(e) => {
              setDeleteUserId(e.target.value);
            }}
            value={deleteuserid}
          />
        </div>
        <div className="delete-btn">
          <button className="" onClick={deleteUser}>
            {" "}
            Delete User{" "}
          </button>
        </div>

        <p>{deletemsg ? deletemsg : ""}</p>
      </div>

      <div className="edit-img-upload">
            <input
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
              }}
            />
            <div className="img-details">
              {loading && "Loading..."}

              {<button onClick={uploadFile}> Update Image</button>}

              {
                <img
                  src={imageUrls ? imageUrls : blankAvatar}
                  height="200"
                  width="200"
                  alt="Member"
                />
              }
            </div>
            <p>
              {" "}
              {imgName ? (
                <p>
                  {" "}
                  Image name: {imgName} <br /> Image size: {imgSize} KB
                </p>
              ) : (
                ""
              )}
            </p>
          </div>

</div>


      
    </section></>
  );
};

export default EditMember;
